<!-- Notifications toggle -->
<button class="notifications-toggle"
        mat-icon-button
        (click)="openPanel()"
        #notificationsOrigin>
    <mat-icon *ngIf="unreadCount > 0" matBadge="{{unreadCount}}" matBadgeColor="warn"
              [svgIcon]="'notifications_active'"></mat-icon>
    <mat-icon *ngIf="unreadCount === 0"
              [svgIcon]="'notifications'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="notifications-panel">

        <div class="header">

            <div class="title">
                Notificaciones
            </div>

            <button class="mark-all-as-read"
                    mat-icon-button
                    [matTooltip]="'Marcar todos como leídos'"
                    (click)="markAllAsRead()">
                <mat-icon [svgIcon]="'playlist_add_check'"></mat-icon>
            </button>

        </div>

        <div class="content">

            <!-- Notifications -->
            <ng-container *ngFor="let notification of notifications">

                <div class="notification"
                     [ngClass]="{'unread': notification.status == 0}">

                    <!-- Notification with a link -->
                    <ng-container *ngIf="notification.link">
                        <!-- Normal links -->
                        <a *ngIf="!notification.useRouter"
                           [href]="notification.link">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>

                        <!-- Router links -->
                        <a *ngIf="notification.useRouter"
                           [routerLink]="createRouteFromLink(notification.link)">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>
                    </ng-container>

                    <!-- Notification without a link -->
                    <span *ngIf="!notification.link">
                        <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                    </span>

                    <!-- Actions -->
                    <div class="actions">

                        <!-- Indicator -->
                        <button class="indicator"
                                mat-icon-button
                                (click)="toggleRead(notification)"
                                [matTooltip]="notification.status == 1 ? 'Marcar como no leído' : 'Marcar como leído'">
                            <span *ngIf="notification.status == 0"
                                  class="unread"></span>
                            <span *ngIf="notification.status == 1"
                                  class="read"></span>
                        </button>

                        <button class="remove"
                                mat-icon-button
                                (click)="delete(notification)"
                                [matTooltip]="'Eliminar'">
                            <mat-icon [svgIcon]="'close'"></mat-icon>
                        </button>

                    </div>

                </div>

                <ng-template #notificationContent>

                    <!-- Icon -->
                    <mat-icon class="icon"
                              [svgIcon]="'mail'">
                    </mat-icon>

                    <!-- Title, description & time -->
                    <div class="notification-content">
                        <div class="title"
                             *ngIf="notification.title"
                             [innerHTML]="notification.title"></div>
                        <div class="description"
                             *ngIf="notification.message"
                             [innerHTML]="notification.message"></div>
                        <div class="time">{{notification.createdAt | date:'MMM dd, h:mm a'}}</div>
                    </div>

                </ng-template>

            </ng-container>


            <ng-container *ngIf="!notifications || !notifications.length">
                <div class="empty">
                    <mat-icon [svgIcon]="'notifications'"></mat-icon>
                    <span>No tiene notificaciones</span>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>
