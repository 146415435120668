import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractHttpService } from '@services/abstract-http.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ReportProblemService extends AbstractHttpService {

  constructor(protected http: HttpClient) {
    super(http);
  } 

  findByName(name: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const filter =
      '{ "where" : { "title": "' + name + '"}}';
    return this.http
      .get<any>(
        this.apiUrl + '/report-problems?filter=' + encodeURIComponent(filter),
        httpOptions
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  findById(slug: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(`${this.apiUrl}/report-problems/${slug}`, httpOptions).pipe(
      map(response => {
        return response;
      })
    );
  }
  add(problem: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'      
      })
    };
    return this.http.post<any>(`${this.apiUrl}/report-problems`, problem, httpOptions).pipe(
      map(response => {
        return response;
      })
    );
  }
}
