import { NgModule } from '@angular/core';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { ClassyLayoutModule } from 'app/layout/layouts/vertical/classy/classy.module';
import { SharedModule } from 'app/shared/shared.module';
import { MasterPublicComponent } from './master-public/master-public.component';
import { MasterPrivateComponent } from './master-private/master-private.component';

const modules = [
    EmptyLayoutModule,
    ClassyLayoutModule,
];

@NgModule({
    declarations: [
        MasterPublicComponent,
        MasterPrivateComponent
    ],
    imports     : [
        SharedModule,
        ...modules
    ],
    exports     : [
        ...modules
    ]
})
export class LayoutModule
{
}
