import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/layout/common/user/user.types';
import { UserService } from 'app/layout/common/user/user.service';
import { Session } from '@models/session';
import { ReportProblemComponent } from '@shared/report-problem/report-problem.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SessionService     } from '@services/session.service';
import { AuthService } from '@services/auth.service';
@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    styleUrls      : ['./user.component.scss'],
    providers      : [AuthService],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit, OnDestroy
{
    @Input()
    showAvatar: boolean;
    // Private
    private _unsubscribeAll: Subject<any>;
    private subscription: Subscription = new Subscription();
    private _user: User;
    profile: Session;
    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {Router} _router
     * @param {UserService} _userService
     */
    constructor(
        public dialog: MatDialog,
        private _router: Router,
        private authService: AuthService,
        private _userService: UserService,
        private session : SessionService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.showAvatar = true;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    @Input()
    set user(value: User)
    {
        // Save the user
        this._user = value;

        // Store the user in the service
        this._userService.user = value;
    }

    get user(): User
    {
        return this._user;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.profile = JSON.parse(localStorage.getItem("profile"));
        this.session.updateProfile$.subscribe( profile => {
            this.profile = profile;
        });
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this._user = user;
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status): void
    {
        // Update the user data
        this.user.status = status;

        // Update the user on the server
        this._userService.update(this.user);
    }

    openReportProblem(){
        this.dialog.open(ReportProblemComponent, this.getNewsDialogConfig());
    }
    
    private getNewsDialogConfig() {
    let config = new MatDialogConfig();
    config = {
        disableClose: false,
        data: {
        },
        panelClass: 'problem-dialog',
        maxWidth: '90vw !important'
    };
    return config;
    }
    
    goToPage(page : string){
        this._router.navigate([page]);
    }

    /**
     * Sign out
     */
    logout(){
        this.subscription.add(this.authService.logout(this.profile.rut, 'sign-out').subscribe());
        localStorage.removeItem("profile")
        localStorage.removeItem('vpnStatus');
        localStorage.removeItem('rpaStatus');
        this._router.navigate(['/sign-in']);
    }
}
