<body>
    <div mat-dialog-content>
        <div _nghost-xjy-c131="" class="ng-star-inserted">
            <a class="imagen">
                <a href="{{data.imagePath}}"><img mat-card-image class="mb-2"
                    src="{{data.imagePath}}"
                    alt="{{data.imagePath}}"></a>
            </a>
            <!--<div class="">
                <h4 _ngcontent-xjy-c131="" class="titulo">Descripción</h4>
                <h4 class="fs_7 fw_light c-grey_1">{{data.description}}</h4>
                <h4 _ngcontent-xjy-c131="" class="titulo">Condiciones</h4>
                <h4 class="fs_7 fw_light c-grey_1">{{data.conditions}}</h4>
            </div>-->
        </div>
        <div class="buttonEntendido">
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" (click)="checked(data.id)">
                Entendido
            </button>
        </div>
    </div>
</body>