import { Injectable } from '@angular/core';

@Injectable()
export class ConstantService {
    
    public static ContentTypeJson                   = "application/json";
    public static ContentTypeXml                    = "application/xml;";
    public static ContentTypePdf                    = "application/pdf;";
    public static ContentTypeUrlEncoded             = "application/x-www-form-urlencoded;";
    public static ContentTypeMultipart              = "multipart/form-data";
    public static ContentTypeTextPlain              = "text/plain;";
    public static ContentTypeImageJpg               = "image/jpeg;";
    public static ContentTypeImagePng               = "image/png;";
    public static ContentTypeImageGif               = "image/gif;";
    public static EncodingUTF8                      = "UTF-8"; 
    public static EncodingISO88959                  = "ISO-8859-1";
    public static LangCodeES                        = "es";
    public static UnknownError                      = "Unknown Error";
    public static Anonymous                         = "Anonymous"
    public static paginationDesktop                 = 50;
    public static paginationMobile                  = 20
    public static formatDate                        = "DD-MM-YYYY HH:mm";
    public static simpleDate                        = "DD-MM-YYYY";
    public static snackDuration                     = 4000;
    public static PERM_CARGO_LECTURA                = "cargo-lectura";
    public static PERM_CLIENTE_LECTURA              = "cliente-lectura";
    public static PERM_COMPANIA_LECTURA             = "compania-lectura";
    public static PERM_DASHBOARD_LECTURA            = "dashboard-lectura";
    public static PERM_DEPARTAMENTO_LECTURA         = "departamento-lectura";
    public static PERM_MIS_SOLICITUDES_LECTURA      = "mis-solicitudes-lectura";
    public static PERM_MIS_SOLICITUDES_ESCRITURA    = "mis-solicitudes-escritura";
    public static PERM_FORMULARIO_LECTURA           = "mis-formularios-lectura";
    public static PERM_FORMULARIO_ESCRITURA         = "mis-formularios-escritura";
    public static PERM_GRUPO_LECTURA                = "grupo-lectura";
    public static PERM_GRUPO_ESCRITURA              = "grupo-escritura";
    public static PERM_HISTORIAL_LECTURA            = "historial-lectura";
    public static PERM_MIS_TICKETS_LECTURA          = "mis-tickets-lectura";
    public static PERM_MIS_RETIROS_LECTURA          = "mis-retiros-lectura";
    public static PERM_DASHBOARD_RETIROS_LECTURA          = "dashboard-retiros-lectura";
    public static PERM_DASHBOARD_RETIROS_ESCRITURA          = "dashboard-retiros-escritura";
    public static PERM_MIS_RETIROS_ESCRITURA          = "mis-retiros-escritura";
    public static PERM_RESUMEN_RETIRO_DE_EQUIPOS_LECTURA    = "indicador-retiro-equipos-chopper-lectura";
    public static PERM_RESUMEN_RETIRO_DE_EQUIPOS_ESCRITURA  = "indicador-retiro-equipos-chopper-escritura";
    public static PERM_ORDEN_TRABAJO_LECTURA        = "orden-de-trabajo-lectura";
    public static PERM_PERFIL_LECTURA               = "perfil-lectura";
    public static PERM_PERFIL_ESCRITURA             = "perfil-escritura";
    public static PERM_PRIVILEGIO_LECTURA           = "privilegio-lectura";
    public static PERM_PRIVILEGIO_ESCRITURA         = "privilegio-escritura";
    public static PERM_USUARIO_LECTURA              = "usuario-lectura";
    public static PERM_USUARIO_ESCRITURA            = "usuario-escritura";
    public static PERM_SOLICITUD_HISTORIAL_LECTURA       = "mis-solicitudes-lectura";
    public static PERM_SOLICITUD_HISTORIAL_ESCRITURA     = "mis-solicitudes-escritura";
    public static PERM_MIS_TICKETS_SIN_ASIGNAR_LECTURA   = "tickets-sin-asignar-lectura";
    public static PERM_MIS_TICKETS_SIN_ASIGNAR_ESCRITURA = "tickets-sin-asignar-escritura";
    public static PERM_COORDINADOR_LECTURA               = "coordinador-lectura";
    public static PERM_COORDINADOR_ESCRITURA             = "coordinador-escritura";
    public static PERM_RECURSOS_HUMANOS_LECTURA          = "recursos-humanos-lectura";
    public static PERM_RECURSOS_HUMANOS_ESCRITURA        = "recursos-humanos-escritura";
    public static PERM_REPORTES_LECTURA                  = "reportes-lectura";
    public static PERM_REPORTES_EO_LECTURA               = "reporte-excepciones-operativas-lectura";
    public static PERM_REPORTES_PO_LECTURA               = "reporte-problemas-operativos-lectura";
    public static PERM_REPORTES_RS_LECTURA               = "reporte-reportar-a-mi-supervisor-lectura";
    public static PERM_REPORTES_CM_LECTURA               = "reporte-excepciones-caida-de-sistema-lectura";
    public static PERM_REPORTES_NDC_LECTURA              = "reporte-excepciones-puntos-ndc-lectura";
    public static PERM_REPORTES_BP_LECTURA              = "reporte-bono-produccion-lectura";
    public static PERM_REPORTES_AC_LECTURA              = "reporte-asistencia-choppers-lectura";
    public static PERM_REPORTES_UP_LECTURA              = "reporte-cuadratura-ndc-lectura";
    public static PERM_CARGA_MASIVA_LECTURA              = "carga-masiva-lectura";
    public static PERM_REPORTE_EXCEPCION_CAIDA_SISTEMA_LECTURA      = "reportar-excepcion-caida-sistema-lectura";
    public static PERM_MIS_INDICADORES_LECTURA           = "mis-indicadores-lectura";
    public static PERM_MIS_INDICADORES_ESCRITURA         = "mis-indicadores-escritura";
    public static PERM_SERVICIOS_LECTURA               = "servicios-lectura";
    public static PERM_SERVICIOS_ESCRITURA             = "servicios-escritura";
    public static PERM_REFERIDOS_LECTURA                 = "referidos-lectura";
    public static PERM_REFERIDOS_ESCRITURA               = "referidos-escritura";
    public static PERM_NOVEDADES_LECTURA               = "novedades-lectura";
    public static PERM_NOVEDADES_ESCRITURA             = "novedades-escritura";
    public static PERM_REFERIDOS_FAQ_ESCRITURA               = "referidos-faq-escritura";
    public static PERM_REFERIDOS_FAQ_LECTURA                 = "referidos-faq-lectura";
    public static PERM_GESTION_VENTAS_LECTURA            = "referidos-ventas-lectura";
    public static PERM_GESTION_VENTAS_ESCRITURA          = "referidos-ventas-escritura";
    public static PERM_BUSCADOR_OT_LECTURA                 = "buscador-ot-lectura"
    public static PERM_BUSCADOR_OT_ESCRITURA                 = "buscador-ot-escritura"
    public static PERM_PRELIQUIDACION_LECTURA               = "mi-preliquidacion-lectura";
    public static PERM_LOGISTICA_LECTURA               = "logistica-lectura";
    public static PERM_EQUIPAMIENTO_LECTURA               = "equipamiento-lectura";
    public static PERM_REPORTE_PUNTOS_NDC_LECTURA              = "reporte-puntos-ndc-lectura";
    public static PERM_UAL                                  = "ual-lectura";
    public static PERM_SISTEMA_LECTURA                  = "sistema-lectura";
    public static PERM_SISTEMA_ESCRITURA                  = "sistema-escritura";
    public static PERM_REPORTES_RETIRO_LECTURA            = "reporte-retiro-de-materiales-lectura";
    public static PERM_REPORTES_OTS_LECTURA            = "reporte-materiales-por-ot-toa-lectura";
    public static PERM_REPORTES_EQUIPAMIENTO_ASIGNADO_LECTURA      = "reporte-equipamiento-asignado-lectura";
    public static PERM_STATUS_CIERRRE_OTS      ="status-cierre-ots-lectura";
    public static PERM_STATUS_VPN            = "status-vpn-lectura";
  }