import { IconsMockApi } from 'app/data/mock/ui/icons';
import { NavigationMockApi } from 'app/data/mock/common/navigation';
import { NotificationsMockApi } from 'app/data/mock/common/notifications';
import { MessagesMockApi } from 'app/data/mock/common/messages';
import { ShortcutsMockApi } from 'app/data/mock/common/shortcuts';
import { UserMockApi } from 'app/data/mock/common/user';

export const mockDataServices = [
    IconsMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    ShortcutsMockApi,
    UserMockApi
];
