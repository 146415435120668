import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TreoModule } from '@treo';
import { TreoConfigModule } from '@treo/services/config';
import { TreoMockApiModule } from '@treo/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockDataServices } from 'app/data/mock';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AppInterceptor } from './app.interceptor';
import { AuthGuard} from '@services/auth.guard';
import { DatePipe } from '@angular/common';
import   '@angular/common/locales/global/es';
import { NewsService } from 'app/modules/admin/home/news/news.service';

import { registerLocaleData } from '@angular/common';
import { UserService } from '@modules/admin/users/user/user.service';
import { AuthService } from '@services/auth.service';
//registerLocaleData(localeEs);


const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'enabled' 
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
  }

@NgModule({
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        
        // Treo & Treo Mock API
        TreoModule,
        TreoConfigModule.forRoot(appConfig),
        TreoMockApiModule.forRoot(mockDataServices),

        // Core
        CoreModule,

        // Layout
        LayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
          })
    ],
    providers: [
        AuthGuard, DatePipe, NewsService, UserService, AuthService,
        { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true, }
      ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
