<!-- Button -->
<notifications></notifications>
<button class="icon-button"
        mat-icon-button
        [matMenuTriggerFor]="userActions">
    <span class="avatar">
        <img *ngIf="showAvatar && user.avatar"
             [src]="user.avatar">
        <mat-icon *ngIf="!showAvatar || !user.avatar"
                  [svgIcon]="'account_circle'"></mat-icon>
        <span class="status online"></span>
    </span>
</button>

<mat-menu class="user-actions-menu"
          [xPosition]="'before'"
          #userActions="matMenu">
    <button mat-menu-item>
        <span class="user-info">
            <span>{{ profile.name}}</span>
            <span class="email">{{ profile.role.name}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="goToPage('/admin/my-profile')">
        <mat-icon [svgIcon]="'account_circle'"></mat-icon>
        <span>Mi perfil</span>
    </button>
    <button mat-menu-item (click)="openReportProblem()">
        <mat-icon [svgIcon]="'heroicons_outline:support'"></mat-icon>
        <span>Reportar problema</span>
    </button>
    <!-- <button mat-menu-item>
        <a target="_blank" href="https://wa.link/6os2wk">
        <mat-icon  class="material-icons green-color" style="background:#FFF;border-radius:100px">whatsapp </mat-icon>
        <span>Whatsapp Interno</span>
        </a>
    </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item
            (click)="logout()">
        <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
        <span>Desconectar</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu"
          #userStatus="matMenu">
    <button mat-menu-item
            (click)="updateUserStatus('online')">
        <span class="status online"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('away')">
        <span class="status away"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('busy')">
        <span class="status busy"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item
            (click)="updateUserStatus('not-visible')">
        <span class="status not-visible"></span>
        <span>Invisible</span>
    </button>
</mat-menu>
