import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications_in_use/notifications.service';

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    styleUrls      : ['./notifications.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications'
})
export class NotificationsInUseComponent implements OnInit, OnDestroy
{
    unreadCount: number;
    public profile =  JSON.parse(localStorage.getItem('profile'));
    public notifications: any[];
    public loading: boolean = true;

    // Private
    private subscription: Subscription = new Subscription();
    private _notifications: Notification[];
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any>;
    private timeCall: number;

    @ViewChild('notificationsOrigin')
    private _notificationsOrigin: MatButton;

    @ViewChild('notificationsPanel')
    private _notificationsPanel: TemplateRef<any>;

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {NotificationsService} _notificationsService
     * @param {Overlay} _overlay
     * @param {ViewContainerRef} _viewContainerRef
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.unreadCount = 0;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    async ngOnInit(): Promise<void>
    {
        // Load the notifications
        /*this.getNotifications();
        console.log("this.timeCall");
        console.log(this.timeCall);*/
        this.subscription.add(this._notificationsService.getWebByRut(this.profile.rut).subscribe(
            response => {
                if (response) {
                    console.log(response);
                    this.notifications = response.notifications;
                    this.timeCall = response.timeCall;
                    // Calculate the unread count
                    this.getUnreadCount();
                    this._changeDetectorRef.markForCheck();
                    if (this.timeCall) {
                        var bellProcess = setInterval(() => {
                            if (JSON.parse(localStorage.getItem('profile')))
                                this.getNotifications();
                            else
                                clearInterval(bellProcess);
                        }, this.timeCall);
                    }
                }   
            },
            error => {
                console.log(error);
            }
        ));
        

        // Mark for check
        
    }

    getNotifications() {
        this.subscription.add(this._notificationsService.getWebByRut(this.profile.rut).subscribe(
            response => {
                if (response) {
                    console.log(response);
                    this.notifications = response.notifications;
                    this.timeCall = response.timeCall;
                    // Calculate the unread count
                    this.getUnreadCount();
                    this._changeDetectorRef.markForCheck();
                    return response;
                }   
            },
            error => {
                console.log(error);
            }
        ));
    }
    getUnreadCount() {
        this.unreadCount = this.notifications.filter((notification) => notification.status === 0).length;
        console.log(this.unreadCount);
        this.loading = false;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        // Dispose the overlay if it's still on the DOM
        if ( this._overlayRef )
        {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void
    {
        let count = 0;
        if ( this.notifications && this.notifications.length )
        {
            count = this.notifications.filter((notification) => notification.status === 0).length;
        }

        this.unreadCount = count;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create route from given link
     */
    createRouteFromLink(link): string[]
    {
        // Split the link and add a leading slash
        const route = link.split('/');
        route.unshift('/');

        // Return the route
        return route;
    }

    /**
     * Open the notifications panel
     */
    openPanel(): void
    {
        console.log("openPanel");
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : '',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                                  .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                                  .withFlexibleDimensions()
                                  .withViewportMargin(16)
                                  .withLockedPosition()
                                  .withPositions([
                                      {
                                          originX : 'start',
                                          originY : 'bottom',
                                          overlayX: 'start',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'start',
                                          originY : 'top',
                                          overlayX: 'start',
                                          overlayY: 'bottom'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'bottom',
                                          overlayX: 'end',
                                          overlayY: 'top'
                                      },
                                      {
                                          originX : 'end',
                                          originY : 'top',
                                          overlayX: 'end',
                                          overlayY: 'bottom'
                                      }
                                  ])
        });

        // Create a portal from the template
        const templatePortal = new TemplatePortal(this._notificationsPanel, this._viewContainerRef);

        // Attach the portal to the overlay
        this._overlayRef.attach(templatePortal);

        // Subscribe to the backdrop click
        this._overlayRef.backdropClick().subscribe(() => {

            // If overlay exists and attached...
            if ( this._overlayRef && this._overlayRef.hasAttached() )
            {
                // Detach it
                this._overlayRef.detach();
            }

            // If template portal exists and attached...
            if ( templatePortal && templatePortal.isAttached )
            {
                // Detach it
                templatePortal.detach();
            }
        });
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void
    {
        console.log("markAllAsRead");
        this.subscription.add(this._notificationsService.markAllAsRead(this.profile.rut).subscribe(
            response => {
                this.getNotifications();
            },
            error => {
                console.log(error);
            }
        ));
        // Mark all as read
        //this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification): void
    {
        console.log("toggleRead");
        // Toggle the read status
        notification.status == 0 ? notification.status = 1 : notification.status = 0;
        this.subscription.add(this._notificationsService.update(notification).subscribe(
            response => {
                this.getNotifications();
            },
            error => {
                console.log(error);
            }
        ));

        // Update the notification
        //this._notificationsService.update(notification.id, notification).subscribe();
    }

    /**
     * Delete the given notification
     */
    delete(notification): void
    {
        // Delete the notification
        notification.status = -1;
        // Toggle the delete status
        this.subscription.add(this._notificationsService.update(notification).subscribe(
            response => {
                this.getNotifications();
            },
            error => {
                console.log(error);
            }
        ));
    }
}
