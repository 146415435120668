/* tslint:disable:max-line-length */
import { TreoNavigationItem } from '@treo/components/navigation';

export const defaultNavigation: TreoNavigationItem[] = [
    {
        id      : 'home',
        title   : 'Menu',
        type    : 'group',
        subtitle: '',
        children: [
            {
                id   : 'dashboard-lectura',
                title: 'Dashboard',
                type : 'basic',
                icon : 'mat_outline:apps',
                link : '/admin/dashboard'
            },
            {
                id   : 'mis-indicadores-lectura',
                title: 'Mis Indicadores',
                type : 'collapsable',
                icon : 'heroicons_outline:dots-circle-horizontal',
                //link : '/admin/mis-indicadores',
                children: [
                    {
                        id   : 'mis-indicadores-lectura',
                        title: 'Mis Puntos',
                        type : 'basic',
                        //icon : 'heroicons_outline:dots-circle-horizontal',
                        link : '/admin/mis-indicadores'
                    },
                    {
                        id: 'mi-preliquidacion-lectura',
                        title: 'Mi Preliquidación',
                        type: 'basic',
                        link: '/admin/home/mis-indicadores/preliq'
                    },{
                        id   : 'mis-indicadores-feedback',
                        title: 'Estado de Solicitudes',
                        type : 'basic',
                        //icon : 'heroicons_outline:document-report',
                        link : '/admin/feedback-estado'
                    } 
                   /* {
                        id   : 'mis-indicadores-feedback',
                        title: 'Estado Excepciones Puntos',
                        type : 'basic',
                        //icon : 'heroicons_outline:document-report',
                        link : '/admin/mis-indicadores/estado'
                    }*/
                ]
            },
            {
                id   : 'mis-retiros-lectura',
                title: 'Retiro Series',
                type: "collapsable",
                icon : 'heroicons_outline:ticket',
                //link : '/admin/request-history-withdrawal'
                children: [
                    {
                        id   : 'mis-retiros-lectura',
                        title: 'Mis Retiros',
                        type : 'basic',
                        //icon : 'heroicons_outline:dots-circle-horizontal',
                        link : '/admin/request-history-withdrawal'
                    },
                    {
                        id   : 'indicador-retiro-equipos-chopper-lectura',
                        title: 'Resumen Retiro de Equipos',
                        type : 'basic',
                        //icon : 'heroicons_outline:document-report',
                        link: '/admin/home/mis-indicadores/resumen-retiro-de-equipos'
                    } ,
                    {
                        id   : 'dashboard-retiros-lectura',
                        title: 'Dashboard Retiros',
                        type : 'basic',
                        //icon : 'heroicons_outline:dots-circle-horizontal',
                        link : '/admin/dashboard-withdrawal'
                    },
                ]
            },
            {
                id: "equipamiento-lectura",
                title: "Mi Equipamiento",
                type: "basic",
                icon: 'heroicons_outline:desktop-computer',
                link: "/admin/logistica/equipamiento/list",
            },
            {
                id   : 'mis-solicitudes-lectura',
                title: 'Reportar Problema',
                type : 'basic',
                icon : 'heroicons_outline:chart-bar',
                link : '/admin/my-surveys'
            },
            {
                id   : 'mis-tickets-lectura',
                title: 'Mis Solicitudes',
                type : 'basic',
                icon : 'heroicons_outline:ticket',
                link : '/admin/request-history'
            },
            {
                id   : 'tickets-sin-asignar-lectura',
                title: 'Solicitudes Sin Asignar',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/admin/request-unassigned'
            },  
            {
                id   : 'historial-lectura',
                title: 'Historial',
                type : 'basic',
                icon : 'heroicons_outline:duplicate',
                link : '/admin/survey-history'
            },
            {
                id      : 'reportar-excepcion-caida-sistema-lectura',
                title   : 'Excepciones por Bonificación',
                type    : 'basic',
                icon    : 'heroicons_outline:exclamation',
                link    : '/admin/request-massive'
            },
            {
                id   : 'compania-lectura',
                title: 'Compañia',
                type : 'basic',
                icon : 'heroicons_outline:office-building',
                link : '/admin/org/company'
            },
            {
                id   : 'departmento-lectura',
                title: 'Departamento',
                type : 'basic',
                icon : 'heroicons_outline:share',
                link : '/admin/org/department'
            },
            {
                id   : 'cargo-lectura',
                title: 'Cargo',
                type : 'basic',
                icon : 'heroicons_outline:collection',
                link : '/admin/org/charge'
            },
            {
                id   : 'grupo-lectura',
                title: 'Grupo',
                type : 'basic',
                icon : 'heroicons_outline:user-group',
                link : '/admin/org/group'
            },
            {
                id   : 'mis-formularios-lectura',
                title: 'Mis Formularios',
                type : 'basic',
                icon : 'heroicons_outline:clipboard-list',
                link : '/admin/org/form'
            },
            {
                id   : 'recursos-humanos-lectura',
                title: 'Recursos Humanos',
                type : 'basic',
                icon : 'heroicons_outline:currency-dollar',
                link : '/admin/org/humanresources'
            },
            {
                id   : 'cliente-lectura',
                title: 'Cliente',
                type : 'basic',
                icon : 'heroicons_outline:user-circle',
                link : '/admin/client/customer'
            },
            {
                id   : 'orden-de-trabajo-lectura',
                title: 'Ordenes de trabajo',
                type : 'basic',
                icon : 'heroicons_outline:calendar',
                link : '/admin/client/work-order'
            },
            {
                id   : 'status-vpn-lectura',
                title: 'Redes',
                type : 'collapsable',
                icon : 'heroicons_outline:globe-alt',
                children: [
                    {
                        id   : 'status-vpn-lectura',
                        title: 'Status VPN',
                        type : 'basic',
                        icon : 'heroicons_outline:globe-alt',
                        link : 'home/status-vpn'
                    }
                ]
            },
            {
                id      : 'usuario-lectura',
                
                title   : 'Usuarios',
                type    : 'basic',
                icon    : 'heroicons_outline:user',
                link    : '/admin/users/user'
            },
            {
                id      : 'coordinador-lectura',
                title   : 'Coordinador',
                type    : 'basic',
                icon    : 'heroicons_outline:users',
                link    : '/admin/users/coordinator'
            },
            {
                id      : 'ual-lectura',
                title   : 'UAL',
                type    : 'basic',
                icon    : 'heroicons_outline:users',
                link    : '/admin/users/ual'
            },
            {
                id      : 'perfil-lectura',
                title   : 'Perfil',
                type    : 'basic',
                icon    : 'heroicons_outline:shield-check',
                link    : '/admin/users/role'
            },
            {
                id      : 'privilegio-lectura',
                title   : 'Privilegio',
                type    : 'basic',
                icon    : 'heroicons_outline:eye',
                link    : '/admin/users/privilege'
            },
            {
                id      : 'carga-masiva-lectura',
                title   : 'Carga Masiva',
                type    : 'basic',
                icon    : 'heroicons_outline:upload',
                link    : '/admin/org/load'
            },
            {
                id   : 'reportes-lectura',
                title: 'Reportes',
                type : 'collapsable',
                icon : 'heroicons_outline:document-report',
                subtitle: '',
                //link : '/admin/org/report/eo'
                children: [
                    {
                        id   : 'reporte-excepciones-operativas-lectura',
                        title: 'Problema vehículo o logística',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/eo'
                    },
                    {
                        id   : 'reporte-problemas-operativos-lectura',
                        title: 'Problemas operativos',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/po'
                    },
                    {
                        id   : 'reporte-reportar-a-mi-supervisor-lectura',
                        title: 'Apoyo Supervisor',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/rs'
                    },
                    {
                        id   : 'reporte-excepciones-caida-de-sistema-lectura',
                        title: 'Reporte Bonificacion de Puntos',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/cm'
                    },
                    {
                        id   : 'reporte-excepciones-puntos-ndc-lectura',
                        title: 'Solicitudes NDC',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/ndc'
                    },
                    /*{
                        id   : 'reporte-consolidado-excepciones-lectura',
                        title: 'Consolidado de Excepciones',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/consolidado'
                    },*/
                    {
                        id   : 'reporte-consolidado-excepciones-lectura',
                        title: 'Consolidado de Puntos',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/consolidadopuntos'
                    },
                    {
                        id   : 'reporte-bono-produccion-lectura',
                        title: 'Bono Producción',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/bp'
                    },
                    {
                        id   : 'dashboard-lectura',
                        title: 'Consumo Combustible',
                        type : 'basic',
                        icon : 'heroicons_outline:calendar',
                        link : '/admin/org/report/consumo-combustible'
                    },
                    {
                        id   : 'dashboard-lectura',
                        title: 'Multas y TAG',
                        type : 'basic',
                        icon : 'heroicons_outline:office-building',
                        link : '/admin/org/report/multas-tag'
                    },
                    {
                        id   : 'reporte-puntos-ndc-lectura',
                        title: 'Reporte NDC',
                        type : 'basic',
                        icon : 'heroicons_outline:office-building',
                        link : '/admin/org/report/ndc-detail'
                    },
                    {
                        id   : 'reporte-cuadratura-ndc-lectura',
                        title: 'Trabajos no encontrados',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/up'
                    },
                    {
                        id   : 'reporte-reportar-a-apoyo-ual-lectura',
                        title: 'Reporte Apoyo UAL',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/ual'
                    },
                    {
                        id   : 'reporte-retiro-de-materiales-lectura',
                        title: 'Retiro de Materiales',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/retiro-materiales'
                    },
                    {
                        id   : 'reporte-materiales-por-ot-toa-lectura',
                        title: 'Materiales por OT TOA',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/resumen-ots'
                    },
                    {
                        id   : 'reporte-equipamiento-asignado-lectura',
                        title: 'Equipamiento Asignado',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/equipamiento-asignado'
                    },
                    {
                        id   : 'status-cierre-ots-lectura',
                        title: 'Status Bot Cierre OTs',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/status-cierre-ots'
                    },
                    {
                        id   : 'reporte-asistencia-choppers-lectura',
                        title: 'Asistencia Choppers',
                        type : 'basic',
                        icon : 'heroicons_outline:document-report',
                        link : '/admin/org/report/chopper-attendance'
                    },
                ]
                
            },
            {
                id: "novedades-lectura",
                title: "Novedades",
                type: "collapsable",
                icon: "heroicons_outline:badge-check",
                link: "/admin/news",
                children: [
                  {
                    id: "novedades-escritura",
                    title: "Agregar Novedad",
                    type: "basic",
                    icon: "heroicons_outline:badge-check",
                    link: "/admin/news/add",
                  },
                  {
                    id: "novedades-lectura",
                    title: "RRHH",
                    type: "basic",
                    icon: "heroicons_outline:user-group",
                    link: "/admin/news/HHRR",
                  },
                  {
                    id: "novedades-lectura",
                    title: "Operaciones",
                    type: "basic",
                    icon: "heroicons_outline:desktop-computer",
                    link: "/admin/news/Operations",
                  },
                  {
                    id: "novedades-lectura",
                    title: "Logística",
                    type: "basic",
                    icon: "heroicons_outline:support",
                    link: "/admin/news/Logistics",
                  },
                ],
            },
            {
                id: "buscador-ot-lectura",
                title: "Buscar OT",
                type: "basic",
                icon: "heroicons_outline:search",
                link: "/admin/search-ot",
            },
            {
                id: "sistema-lectura",
                title: "Sistema",
                type: "collapsable",
                icon: "feather:cpu",
                subtitle: '',
                children: [
                    {
                        id: "sistema-lectura",
                        title: "Logs",
                        type: "basic",
                        icon: "heroicons_outline:terminal",
                        link: "/admin/sistema/trx-logs/list"
                    },
                    {
                        id   : 'sistema-lectura',
                        title: 'Status RPA',
                        type : 'basic',
                        icon : 'heroicons_outline:search',
                        link : 'home/status-rpa'
                    },
                    {
                        id   : 'sistema-lectura',
                        title: 'Monitor Usuarios',
                        type : 'basic',
                        icon : 'heroicons_outline:desktop-computer',
                        link : '/admin/sistema/monitor-usuarios/list'
                    }
                ],
            },
            {
                id: "logistica-lectura",
                title: "Logística",
                type: "collapsable",
                icon: "heroicons_outline:inbox-in",
                subtitle: '',
                children: [
                  {
                    id: "logistica-lectura",
                    title: "Control Celular",
                    type: "basic",
                    
                    link: "/admin/logistica/ctrcelular/list",
                  }
                ],
            }
            
        ]
    }
];
