import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractHttpService } from '@services/abstract-http.service';
import { map } from 'rxjs/operators';
import { ConstantService } from '@services/constant.service';
import { News } from '@models/news';
import { Group } from '@models/group';

@Injectable()
export class NewsService extends AbstractHttpService {

  constructor(protected http: HttpClient) {
    super(http);
  } 

  count() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .get<any>(
        this.apiUrl + '/news/count', httpOptions)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  countWithParams(params : any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let filter = "";
    let filter2 = this.configureFilters(params);
    filter = '{"order" : ["createdAt DESC"]';

    if (filter2 === ""){
      filter = filter + '}';
    }
    if (filter2 !== ""){
        filter = filter + ', "where": {' + filter2 + '}}';
    }
    return this.http
      .get<any>(
        this.apiUrl + '/news/count?filter=' + encodeURIComponent(filter), httpOptions)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  private configureFilters(params : any){
    var filter = "";
    let arrParams = [];
    if (params.name !== ''){
      arrParams.push({ field: 'name', value : params.name });
    }

    if (params.service !== ''){
      arrParams.push({ field: 'service', value : Number(params.service) });
    }  
    
    if (params.createdBy !== ''){
      arrParams.push({ field: 'createdBy', value : params.createdBy });
    }

    if (params.available !== ''){
      arrParams.push({ field: 'available', value : Boolean(params.available) });
    }  

    for (let i = 0; i < arrParams.length; i ++){
      if (arrParams.length > 1 && i < arrParams.length -1){
        filter =  filter + '"' + arrParams[i].field + '": ' + JSON.stringify(arrParams[i].value) + ',';
      }else {
        filter =  filter + '"' + arrParams[i].field + '": ' + JSON.stringify(arrParams[i].value) + '';
      }
          
    }

    return filter;
  }

  

  findWithParams(params : any, page : number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let skip = 0;
    if (page > 0) {
      skip = ConstantService.paginationDesktop * page;
    }
    let filter = "";
    let filter2 = this.configureFilters(params);
    
    console.log("filter2: ", filter2);
    
    filter = '{ "skip": "' + skip +'", "limit": "' + ConstantService.paginationDesktop + '", "order" : ["createdAt DESC"]';

    if (filter2 === ""){
      filter = filter + '}';
    }
    if (filter2 !== ""){
        filter = filter + ', "where": {' + filter2 + '}}';
    }
    return this.http
      .get<any>(
        this.apiUrl + '/news?filter=' + encodeURIComponent(filter),
        httpOptions
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  findByName(name: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const filter =
      '{ "where" : { "name": "' + name + '"}}';
    return this.http
      .get<any>(
        this.apiUrl + '/news?filter=' + encodeURIComponent(filter),
        httpOptions
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  findByEmail(email: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const filter =
      '{ "where" : { "email": "' + email + '"}}';
    return this.http
      .get<any>(
        this.apiUrl + '/users?filter=' + encodeURIComponent(filter),
        httpOptions
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  findServices(id:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const filter =
      '{ "where" : { "area": "' + id + '"}}';
    return this.http
      .get<any>(
        this.apiUrl + '/services?filter=' + encodeURIComponent(filter),
        httpOptions
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  } 
  
  findByArea(Area: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const filter =
      '{"order" : ["createdAt DESC"], "where" : { "and": [{"available": true}, {"area": "' + Area + '"}, {"status": 1}]}}';

    return this.http
      .get<any>(
        this.apiUrl + '/news?filter=' + encodeURIComponent(filter),
        httpOptions
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getNewsByAreaAndGroup(area:string, group: Group[], rut: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log(group);
    return this.http
      .get<any>(
        this.apiUrl + '/getNewsByAreaAndGroup/'+area+'/'+group+'/'+rut,
        httpOptions
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  findById(id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(`${this.apiUrl}/news/${id}`, httpOptions).pipe(
      map(response => {
        return response;
      })
    );
  }

  add(news: News) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(`${this.apiUrl}/news`, news, httpOptions).pipe(
      map(response => {
        return response;
      })
    );
  }

  remove(id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.delete<any>(`${this.apiUrl}/news/${id}`, httpOptions).pipe(
      map(response => {
        return response;
      })
    );
  }
  changeStatus(id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.put<any>(`${this.apiUrl}/news/changeStatus/${id}`, httpOptions).pipe(
      map(response => {
        return response;
      })
    );
  }

  update(id: string, user: News) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .put<any>(`${this.apiUrl}/news/${id}`, user, httpOptions)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  
  uploadFile(file: FormData) {
    return this.http.post<any>(`${this.apiUrl}/files`, file).pipe(
      map(response => {
        return response;
      })
    );
  }

  private completeZero(value){
    if (Number.parseInt(value) < 10){
      return "0" + value;
    }
    return value;
  }
  findAll() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const filter =
      '{"order" : ["createdAt DESC"], "where" : { "and": [{"available": true}, {"status": 1}]}}';

    return this.http
      .get<any>(
        this.apiUrl + '/news?filter=' + encodeURIComponent(filter),
        httpOptions
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }
 
}
