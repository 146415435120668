<div class="close-btn-outer">
    <button mat-mini-fab color="warn" (click)="close()"><mat-icon>close</mat-icon></button>
</div>
<div mat-dialog-content class="detail-modal">
    <div class="animated-box" @zoomIn>
        <div class="animated-box-content">
            <p class="text-lg font-medium">Reportar Problema</p>
            <p class="text-secondary mb-6">Su feedback es fundamental para seguir mejorando.</p>
            <form [formGroup]="problemForm" class="flex flex-col overflow-hidden">  
                <mat-form-field class="flex-auto">
                    <input matInput formControlName="title"

                            [placeholder]="'Titulo'">
                    <mat-icon matPrefix
                                [svgIcon]="'bookmark'"></mat-icon>
                </mat-form-field>
                <mat-form-field class="treo-mat-textarea flex-auto">
                    <textarea matInput  formControlName="description"
                                [placeholder]="''"
                                 [rows]="12"></textarea>
                    <mat-icon matPrefix
                                [svgIcon]="'heroicons_outline:pencil-alt'"></mat-icon>
                </mat-form-field>

            </form>
            <div class="flex justify-end m-2">
                <button class="px-8 m-2"
                        mat-flat-button
                        [color]="'primary'"
                        [disabled]="problemForm.invalid"
                        type="button"
                        (click)="add()">
                    Enviar
                </button>
            </div>
        </div>
    </div>
</div>