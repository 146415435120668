import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportProblemService } from './report.problem.service'
import { TreoAnimations } from '@treo/animations';
import { Subscription} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConstantService } from '@services/constant.service';

@Component({
  selector: 'app-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.scss'],
  providers: [ ReportProblemService ],
  animations: TreoAnimations
})
export class ReportProblemComponent implements OnInit, OnDestroy {
  problemForm: FormGroup;
  private subscription :Subscription = new Subscription();
  loadingSrv : boolean = false;

  constructor(public formBuilder: FormBuilder, public dialogRef: MatDialogRef<ReportProblemComponent>,
    private srv : ReportProblemService, private snack : MatSnackBar) { }

  ngOnInit(): void {
    this.problemForm = this.formBuilder.group({
      title: [ {value: '', disabled: false}, [ Validators.required, Validators.minLength(3),Validators.maxLength(120)]],
      description: [ {value: '', disabled: false}, [ Validators.required, Validators.minLength(3),Validators.maxLength(ConstantService.snackDuration)]]
    });
  }

  add(){
    this.loadingSrv = true;
    this.subscription.add(this.srv.add({ title: this.problemForm.controls.title.value, description: this.problemForm.controls.description.value}).subscribe(
      response => {
        this.loadingSrv = false;
        this.snack.open('Se ha envíado satisfactoriamente su feedback. Muchas gracias por colaborar con la mejora del sistema.', 'X',
            { panelClass: ['success'], verticalPosition: 'top', duration: ConstantService.snackDuration }
        );
        this.close();
      }, error => {
        this.loadingSrv = false;
        this.snack.open('Se ha producido un error al intentar envíar el feedback.', 'X',
            { verticalPosition: 'top', duration: ConstantService.snackDuration }
        );
      }
    ));
  }
  close(): void {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

}
