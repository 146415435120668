import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TreoCardModule } from '@treo/components/card';
import { TipsDialogComponent } from './dialog/tips-dialog/tips-dialog.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { LoadingComponent } from './loading/loading.component';
import { ReportProblemComponent } from './report-problem/report-problem.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MyPollsComponent } from './my-polls/my-polls.component';
import { MyScoreComponent } from './my-score/my-score.component';
import { MyHistoryComponent } from './my-history/my-history.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateFormatRequest } from './dateFormatPipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        TreoCardModule,
        MatButtonModule,
        RouterModule,
        MatSnackBarModule,
        TranslateModule,
        MatDialogModule,
        MatInputModule,
        MatDividerModule,
        MatFormFieldModule,
        MatProgressSpinnerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule,
        TranslateModule,
        MatDialogModule,
        WelcomeComponent,
        LoadingComponent,
        MyPollsComponent,
        MyScoreComponent,
        MyHistoryComponent,
        MatProgressSpinnerModule,
        DateFormatRequest
    ],
    declarations: [
        TipsDialogComponent, 
        WelcomeComponent, 
        LoadingComponent, 
        ReportProblemComponent, 
        MyPollsComponent, 
        MyScoreComponent,
        MyHistoryComponent,
        DateFormatRequest
    ],
    entryComponents: [
        TipsDialogComponent, ReportProblemComponent
      ],
})
export class SharedModule
{
}
