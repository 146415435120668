import { Component, Inject, Injectable } from "@angular/core";
import { Router, CanActivate } from '@angular/router';
import { Observable, of, Subscription} from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { EndpointService } from "@services/endpoint.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantService } from '@services/constant.service';
import { AbstractHttpService } from '@services/abstract-http.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { News } from '@models/news';
import { NewsService } from 'app/modules/admin/home/news/news.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogNewsDetails } from '@modules/admin/home/news/news.component';
import { UserService } from "@modules/admin/users/user/user.service";
import { User } from '@models/user';
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends AbstractHttpService implements CanActivate 
{
    head = new HttpHeaders({ 'Content-Type': ConstantService.ContentTypeJson });
    private subscription: Subscription = new Subscription();
    newsLogistics: News[] = null;
    newsOperations: News[] = null;
    newsHHRR: News[] = null;
    news: News[] = null;
    parameters = {
        month: "",
        area: "",
        available: true,
    };
    totalElements: number;
    users: User[] = null;
    user: User;
    contNews = 0;
    contDialog = 0;

    /**
     * Constructor
     *
     * @param {AuthService} _authService
     * @param {Router} _router
     */
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private dialog: MatDialog,
        private srv: NewsService, 
        private snack: MatSnackBar,
        private usersrv: UserService,
        private _authService: AuthService
    ){
        super(_httpClient);
    }
    
    canActivate() : Observable<boolean> | Promise<boolean> | boolean {
        return this._httpClient.get<any>(this.apiUrl + EndpointService.Auth_IsLoggedIn, { headers: this.head }).pipe(
            map(response => {
                let profile = JSON.parse(localStorage.getItem('profile'));
                if (profile.checkpopup == false) {
                    this.check();
                }
                return response;
            }),
            catchError(err => {
                localStorage.removeItem("token")
                if (err.error && err.error.error && err.error.error.message.indexOf("jwt expired") > -1){
                    this.router.navigate(['expired']);
                    let profile = JSON.parse(localStorage.getItem('profile'));
                    this.subscription.add(this._authService.logout(profile.rut, 'jwt expired').subscribe(
                      (response) => {
                      }
                    ));
                } else {
                    this.router.navigate(['sign-in']);
                }
                return of(false);
            })      
        );
    }
    private check() {
        let profile = JSON.parse(localStorage.getItem('profile'));
        this.subscription.add(this.srv.findAll().subscribe(
          (response) => {
            this.news = response;
            for (let index = 0; index < response.length; index++) {
              if (this.vigenciaNovedades(response[index])) {
                if (this.groupsNovedades(response[index])) {
                  if (response[index].status == 1) {
                      
                    this.subscription.add(this.usersrv.findPopupHistory(profile.rut, response[index].id).subscribe(
                    (respuesta) => { 
                          
                          if(respuesta == true){
                            //console.log(result);  
                            console.log("mostrando popup");
                            console.log(response[index]);
                            this.verPopup(response[index]);
                          }
                    })); 
                  }
                }
              }
            }
            profile.checkpopup = true;
            localStorage.setItem('profile', JSON.stringify(profile));
          }
        ));
      }
      vigenciaNovedades(news: News) {
        //console.log("vigenciaNovedades()");
        var fechaStart = new Date(news.vigenciaStart);
        var fechaEnd = new Date(news.vigenciaEnd);
        var fechaActual = new Date();
        if (fechaStart <= fechaActual && fechaActual <= fechaEnd) {
            return true;
        }
        return false;
      }
      verPopup(promo: News): void {
        const dialogRef = this.dialog.open(DialogNewsPopup, {
          data: {
            description: promo.description,
            conditions: promo.conditions,
            imagePath: promo.imagePath,
            id: promo.id,
          },
          width: '400px',
          maxHeight: '90vh' //you can adjust the value as per your view
        });
    
        dialogRef.afterClosed().subscribe((result) => {
          console.log("The dialog was closed");
        });
      }
      groupsNovedades(news: News) {
        let encuentra;
        let profile = JSON.parse(localStorage.getItem('profile'));
        for (let i = 0 ; i < news.groups.length; i++) {
          
          encuentra = profile.group.indexOf(news.groups[i]);
          if (encuentra > -1) {
            return true;
          }
            
        }
        return false;
      }
}
@Component({
  selector: "news-popup",
  templateUrl: "popup.dialog.html",
  styleUrls: ["popup.dialog.scss"],
})
export class DialogNewsPopup {
   private subscription: Subscription = new Subscription();
  contDialog = 0;
  constructor(
    private usersrv: UserService,
    public dialogRef: MatDialogRef<DialogNewsPopup>,
    @Inject(MAT_DIALOG_DATA)
    public data: { description: string; conditions: string; imagePath: string,id: string }
  ) {}
  onCloseClick(): void {
    this.dialogRef.close();
  }
  checked(id:string){
      let profile = JSON.parse(localStorage.getItem('profile'));  
      this.subscription.add(this.usersrv.updatePopupHistory(profile.rut,id ).subscribe(
        (respuesta) => { 
            this.contDialog++;
            profile.checkpopup = true;
            localStorage.setItem('profile', JSON.stringify(profile));
            console.log("cerrando popup1");
           console.log(id);
           profile.rut;
           this.dialogRef.close();
        }));
  }
}
