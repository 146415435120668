<!-- Navigation -->
<treo-vertical-navigation class="theme-dark"
                          [appearance]="'classic'"
                          [mode]="isScreenSmall ? 'over' : 'side'"
                          [name]="'mainNavigation'"
                          [navigation]="data.navigation.default"
                          [opened]="!isScreenSmall">

    <div treoVerticalNavigationContentHeader>

        <!-- Actions -->
        <div class="actions">
            <!-- Spacer -->
            <div class="spacer"></div>
        </div>

        <!-- User -->
        <div class="user">
            <div class="avatar">
                <img *ngIf="data.user.avatar"
                     [src]="data.user.avatar">
                <mat-icon *ngIf="!data.user.avatar"
                          [svgIcon]="'account_circle'"></mat-icon>
            </div>
            <div class="info">
                <div class="name">{{profile.name}}</div>
                <div class="email text-secondary">{{profile.email}}</div>
            </div>
        </div>
    </div>

    <div treoVerticalNavigationContentFooter>
        <div class="logo">
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <!-- Header -->
    <div class="header">

        <!-- Navigation toggle button -->
        <button class="navigation-toggle-button"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'menu'"></mat-icon>
        </button>

        <!-- Spacer -->
        <div class="spacer"></div>

        <!-- User -->
        <user [user]="profile"
                [showAvatar]="false"></user>

    </div>

    <!-- Content -->
    <div class="content">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
             it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

</div>
