import { Route } from '@angular/router';
import { InitialDataResolver } from '@resolvers/app.resolvers';
import { AuthGuard } from '@services/auth.guard';
import { MasterPublicComponent } from '@layout/master-public/master-public.component';
import { MasterPrivateComponent } from '@layout/master-private/master-private.component';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    { path: 'admin',   redirectTo: '/admin/mis-indicadores', pathMatch: 'full' },
    { path: 'admin/home',   redirectTo: '/admin/mis-indicadores', pathMatch: 'full' },
    { path: '',   redirectTo: '/admin/mis-indicadores', pathMatch: 'full' },
    {
        path       : 'admin',
        data: {
            layout: 'classy'
        },
        resolve    : {
            initialData: InitialDataResolver,
        },
        component: MasterPrivateComponent,
        children   : [

            {path: 'dashboard', loadChildren: () => import('@modules/admin/home/dashboard/dashboard.module').then(m => m.DashboardModule),  canActivate: [AuthGuard]},
            {path: 'dashboard-withdrawal', loadChildren: () => import('@modules/admin/home/dashboard-withdrawal/dashboard-withdrawal.module').then(m => m.DashboardWithdrawalModule),  canActivate: [AuthGuard]},
            {path: 'my-surveys', loadChildren: () => import('@modules/admin/home/my-surveys/my-surveys.module').then(m => m.MySurveysModule),  canActivate: [AuthGuard]},
            {path: 'mis-indicadores', loadChildren: () => import('@modules/admin/home/mis-indicadores/mis-indicadores.module').then(m => m.MisIndicadoresModule),  canActivate: [AuthGuard]},
            {path: 'feedback-indicadores', loadChildren: () => import('@modules/admin/home/mis-indicadores/mis-indicadores-feedback.module').then(m => m.MisIndicadoresFeedbackModule),  canActivate: [AuthGuard]},
            {path: 'feedback-estado', loadChildren: () => import('@modules/admin/home/mis-indicadores/request-history-feedback.module').then(m => m.RequestHistoryFeedbackModule),  canActivate: [AuthGuard]},
            {path: 'survey-history', loadChildren: () => import('@modules/admin/home/survey-history/survey-history.module').then(m => m.SurveyHistoryModule),  canActivate: [AuthGuard]},
            {path: 'request-history', loadChildren: () => import('@modules/admin/home/request-history/request-history.module').then(m => m.RequestHistoryModule),  canActivate: [AuthGuard]},
            {path: 'request-history-withdrawal', loadChildren: () => import('@modules/admin/home/request-history-withdrawal/request-history-withdrawal.module').then(m => m.RequestHistoryWithdrawalModule),  canActivate: [AuthGuard]},
            {path: 'home/mis-indicadores/resumen-retiro-de-equipos', loadChildren: () => import('@modules/admin/home/mis-indicadores/withdrawal-summary/withdrawal-summary.module').then(m => m.WithdrawalSummaryModule),  canActivate: [AuthGuard]},
            {path: 'request-unassigned', loadChildren: () => import('@modules/admin/home/request-unassigned/request-unassigned.module').then(m => m.RequestUnassignedModule),  canActivate: [AuthGuard]},
            {path: 'my-profile', loadChildren: () => import('@modules/admin/my-profile/my-profile.module').then(m => m.MyProfileModule),  canActivate: [AuthGuard]},
            {path: 'request-massive', loadChildren: () => import('@modules/admin/home/requet-massive/request-massive.module').then(m => m.RequestMassiveModule),  canActivate: [AuthGuard]},
            {path: 'org/load', loadChildren: () => import('@modules/admin/org/load/load.module').then(m => m.LoadModule),  canActivate: [AuthGuard]},
            {path: 'org/company', loadChildren: () => import('@modules/admin/org/company/company.module').then(m => m.CompanyModule),  canActivate: [AuthGuard]},
            {path: 'org/department', loadChildren: () => import('@modules/admin/org/department/department.module').then(m => m.DepartmentModule),  canActivate: [AuthGuard]},
            {path: 'org/group', loadChildren: () => import('@modules/admin/org/group/group.module').then(m => m.GroupModule),  canActivate: [AuthGuard]},
            {path: 'org/charge', loadChildren: () => import('@modules/admin/org/charge/charge.module').then(m => m.ChargeModule),  canActivate: [AuthGuard]},
            {path: 'org/form', loadChildren: () => import('@modules/admin/org/form/form.module').then(m => m.FormModule),  canActivate: [AuthGuard]},
            {path: 'org/report/:slug', loadChildren: () => import('@modules/admin/org/report/report.module').then(m => m.ReportModule),  canActivate: [AuthGuard]},
            {path: 'org/humanresources', loadChildren: () => import('@modules/admin/org/humanresources/hr.module').then(m => m.HRModule),  canActivate: [AuthGuard]},
            {path: 'client/customer', loadChildren: () => import('@modules/admin/client/customer/customer.module').then(m => m.CustomerModule),  canActivate: [AuthGuard]},
            {path: 'client/work-order', loadChildren: () => import('@modules/admin/client/work-order/work-order.module').then(m => m.WorkOrderModule),  canActivate: [AuthGuard]},
            {path: 'users/user', loadChildren: () => import('@modules/admin/users/user/user.module').then(m => m.UserModule),  canActivate: [AuthGuard]},
            {path: 'users/coordinator', loadChildren: () => import('@modules/admin/users/coordinator/coordinator.module').then(m => m.CoordinatorModule),  canActivate: [AuthGuard]},
            {path: 'users/ual', loadChildren: () => import('@modules/admin/users/ual/ual.module').then(m => m.UalModule),  canActivate: [AuthGuard]},
            {path: 'users/role', loadChildren: () => import('@modules/admin/users/role/role.module').then(m => m.RoleModule),  canActivate: [AuthGuard]},
            {path: 'users/privilege', loadChildren: () => import('@modules/admin/users/privilege/privilege.module').then(m => m.PrivilegeModule),  canActivate: [AuthGuard]},
            {path: 'unauthorized', loadChildren: () => import('@modules/admin/error-500/error-500.module').then(m => m.Error500Module),  canActivate: [AuthGuard]},
            {path: 'news', loadChildren: () => import('@modules/admin/home/news/news.module').then(m => m.NewsModule),  canActivate: [AuthGuard]},
            {path: 'search-ot', loadChildren: () => import('@modules/admin/home/search-ot/search-ot.module').then(m => m.SearchOTModule),  canActivate: [AuthGuard]},
            {path: 'logistica/ctrcelular/list', loadChildren: () => import('@modules/admin/logistica/ctrcelular/ctrcelular.module').then(m => m.CtrCelularModule),  canActivate: [AuthGuard]},
            {path: 'logistica/equipamiento/list', loadChildren: () => import('@modules/admin/logistica/equipamiento/equipamiento.module').then(m => m.EquipamientoModule),  canActivate: [AuthGuard]},
            {path: 'sistema/trx-logs/list', loadChildren: () => import('@modules/admin/system/trxlogs/trx-logs.module').then(m => m.trxLogsModule), canActivate: [AuthGuard]},
            {path: 'sistema/monitor-usuarios', loadChildren: () => import('@modules/admin/system/monitor-users/monitor-users.module').then(m => m.MonitorUsersModule), canActivate: [AuthGuard]},
            {path: 'home/mis-indicadores/preliq', loadChildren: () => import('@modules/admin/home/mis-indicadores/mis-indicadores-preliq.module').then(m => m.MisIndicadoresPreliqModule),  canActivate: [AuthGuard]},
            {path: 'home/status-vpn', loadChildren: () => import('@modules/admin/home/status-vpn/status-vpn.module').then(m => m.StatusVpnModule),  canActivate: [AuthGuard]},
            {path: 'home/status-rpa', loadChildren: () => import('@modules/admin/home/status-rpa/status-rpa.module').then(m => m.StatusRpaModule),  canActivate: [AuthGuard]}
                       
        ]
    },
    { 
        path: '', component: MasterPublicComponent, 
        data: {
            layout: 'empty'
        },
        children: 
        [
            {path: 'sign-in', loadChildren: () => import('@modules/public/sign-in/sign-in.module').then(m => m.SignInModule)},
            {path: 'forgot-password', loadChildren: () => import('@modules/public/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'activate-account', loadChildren: () => import('@modules/public/activate-account/activate-account.module').then(m => m.ActivateAccountModule)},

            { path: 'activate/password/:hash', loadChildren: () => import('@modules/public/activate-password/activate-password.module').then(m => m.ActivatePasswordModule)},
            { path: 'expired',                loadChildren: () => import('@modules/public/expired/expired.module').then(m => m.ExpiredModule)},
        ]
    },
    {path: '404', pathMatch: 'full', loadChildren: () => import('@modules/error-404/error-404.module').then(m => m.Error404Module)},
    {path: '**', redirectTo: '404'}     
    
];

